import { ICategory, IDropdownData, IProduct } from "../types";
import axios from "../utils/axios";
import {
	UseInfiniteQueryOptions,
	UseQueryOptions,
	useInfiniteQuery,
	useQuery,
} from "react-query";

export const useMenu = (options?: any) => {
	return useQuery({
		queryKey: "menu",
		queryFn: async () => {
			const { data: responseData } = await axios.get("/menu/active");
			return responseData;
		},
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useGetFilters = (options?: UseQueryOptions<IDropdownData>) => {
	return useQuery({
		queryKey: "sidebar",
		queryFn: async () => {
			const { data: responseData } = await axios.get("/filter/active");
			return responseData;
		},
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

// export const useGetProductsByAttributeIds = (
//   slug: string | undefined,
//   page: number,
//   ids?: string,
//   query?: string,
//   options?: UseQueryOptions
// ) => {
//   return useQuery({
//     queryKey: ["product", slug, page, query, ids],
//     queryFn: async () => {
//       const response = await axios.get(
//         `/product/filtered/${slug}?${query ? "&query=" + query : ""
//           }${Boolean(ids) ? "&ids=" + ids : ""}`.trim()
//       );
//       return response;
//     },
//     refetchOnWindowFocus: false,
//     ...options,
//   });
// };

const PAGE_SIZE = 1000;

export const useGetProductsByAttributeIds = (
	slug: string | undefined,
	ids?: string,
	query?: string,
	options?: UseInfiniteQueryOptions,
) => {
	return useInfiniteQuery({
		queryKey: ["filteredProducts", slug, ids, query],
		queryFn: async ({ pageParam = 1 }) => {
			const response = await axios.get(`/product/filtered/${slug}`, {
				params: {
					_start: (pageParam - 1) * PAGE_SIZE,
					_end: pageParam * PAGE_SIZE,
					ids,
					query,
				},
			});
			return response.data;
		},
		getNextPageParam: (lastPage, allPages) => {
			console.log({ lastPage, allPages: allPages.length });
			const currentPage = allPages.length;
			const totalCount = lastPage.count;
			return currentPage * PAGE_SIZE < totalCount ? currentPage + 1 : undefined;
		},
		...options,
	});
};
export const useGetRelatedProducts = (
	slug: string,
	page: number,
	subClass: string,
	options?: UseQueryOptions,
) => {
	return useQuery<any>({
		queryKey: ["product", slug, page, subClass],
		queryFn: async () => {
			const response = await axios.get(
				`/product/related/${slug}?_end=100&_start=${page}&subClass=${subClass}`.trim(),
			);
			return response;
		},
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useGetProductByID = (
	category: string,
	id: string,
	options?: UseQueryOptions<IProduct>,
) => {
	return useQuery<IProduct>({
		queryKey: ["product", category, id],
		queryFn: async () => {
			const { data } = await axios.get(`/product/${category}/${id}`.trim());
			return data;
		},
		refetchOnWindowFocus: false,
		...options,
	});
};

interface ICategoryResponse {
	data: ICategory[];
}

export const useGetCatogrios = (
	options?: UseQueryOptions<ICategoryResponse>,
) => {
	return useQuery<ICategoryResponse>({
		queryKey: ["category"],
		queryFn: async () => {
			const { data } = await axios.get(`/category/unpaginated`.trim());
			return data;
		},
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useGetProductBySpecSheet = (
	url: string,
	options?: UseQueryOptions<any>,
) => {
	return useQuery<any>({
		queryKey: ["product-sepc-sheet", url],
		queryFn: async () => {
			const data = await axios.get(url);
			return data;
		},
		enabled: !!url,
		refetchOnWindowFocus: false,
		...options,
	});
};
