import React, { useContext, useEffect, useState, useCallback } from "react";
import SidebarDropdown from "./SidebarDropdown";
import SidebarSearch from "../SidebarSearch";
import { ManufacturerContext } from "../../context/ManufacturerContext";
import { useGetFilters } from "../../hooks/useQueryHooks";
import { IDropdownData } from "../../types";
import { useHistory } from "react-router-dom";
import { useSearchParams } from "../../hooks/useSearchParams";
import useAttribute from "../../hooks/useAttribute";

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
	const { data, isLoading } = useGetFilters();
	const history = useHistory();
	const searchParams = useSearchParams();
	const { onAttributeClick } = useAttribute();

	const goToHome = () => {
		setExpandedPanel(null);
		history.push("/");
	};

	const [expandedPanel, setExpandedPanel] = useState<string | null>(null);

	const handleOpenExpandedPanel = (abbr: string) => {
		setExpandedPanel(abbr);
	};
	const handleCloseExpandedPanel = () => {
		setExpandedPanel(null);
	};

	const onCategoryClick = useCallback(
		(slug: string) => {
			if (expandedPanel === slug) return;
			history.push(`/${slug}`);
		},
		[expandedPanel, history],
	);

	return (
		<div className="lg:w-80 lg:mb-0 bg-darker xsm:w-full xsm:mb-2">
			<SidebarSearch goToHome={goToHome} />

			{isLoading &&
				Array.from({ length: 6 }).map((sp, i) => (
					<div
						key={i}
						role="status"
						className="max-w-md p-4 space-y-1 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
					>
						<div className="flex items-center justify-between">
							<div>
								<div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-44"></div>
							</div>
							<div className="w-5 h-5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
						</div>
						<span className="sr-only">Loading...</span>
					</div>
				))}

			{data?.filters?.map((filter) => (
				<SidebarDropdown
					key={filter._id}
					data={filter}
					onCategoryClick={onCategoryClick}
					onAttrClick={onAttributeClick}
					expandedPanel={expandedPanel}
					handleOpenExpandedPanel={handleOpenExpandedPanel}
					handleCloseExpandedPanel={handleCloseExpandedPanel}
				/>
			))}
		</div>
	);
};
export default Sidebar;
