import React, { useEffect, useMemo, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { MdMailOutline as EmailIcon } from "react-icons/md";
import { TiSocialLinkedinCircular as LinkedInIcon } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import { EmailShareButton, LinkedinShareButton } from "react-share";
import "../../App.css";
import { NAVIGATION_BACKGROUND_COLOR } from "../../colors";
import RelatedProducts from "../../components/RelatedProducts";
import Specifications from "../../components/Specifications";
import {
	useGetProductByID,
	useGetProductBySpecSheet,
} from "../../hooks/useQueryHooks";
import { Tooltip } from "antd";
import QuoteModal from "../../components/QuoteModal";
import Warranty from "../../components/Warranty";

interface ProductDetailsProps {}

const S3_IMAGES_LINK = process.env.REACT_APP_AWS_IMAGES_CLOUDFRONT_URI;
const S3_SPEC_SHEET_LINK = process.env.REACT_APP_AWS_SPEC_SHEET_CLOUDFRONT_URI;

const ProductDetails: React.FC<ProductDetailsProps> = () => {
	const location = useLocation();
	const category = location.pathname.split("/")[1];
	const [image, setImage] = useState("");
	const productId = location.pathname.split("/")[4];
	const [activeTab, setActiveTab] = useState("specifications");
	const shareableURl = window.location.href;
	const [copied, setCopied] = useState<boolean>(false);

	const { data, isLoading } = useGetProductByID(category, productId, {
		onSuccess(data) {
			setImage(
				data?.image_1 || data?.image_2 || data?.image_3
					? `${S3_IMAGES_LINK}/${
							data?.image_1 || data?.image_2 || data?.image_3
					  }`
					: "",
			);
		},
	});

	const specSheet = `${S3_SPEC_SHEET_LINK}/${data?.spec_sheet}`;

	const {
		data: specsheet,
		isLoading: specSheetLoading,
		status,
	} = useGetProductBySpecSheet(specSheet as string);
	const specSheetLink =
		specsheet?.status === 200 ? specsheet?.config?.url : null;

	let images = [data?.image_1, data?.image_2, data?.image_3];
	images = images.filter(Boolean);

	const onImageClick = (img: string) => {
		setImage(`${S3_IMAGES_LINK}/${img}`);
	};

	const renderTab = useMemo(() => {
		return {
			specifications: <Specifications data={data} />,
			// @ts-ignore
			related_products: <RelatedProducts data={data} />,
			warranty: <Warranty />,
		};
	}, [data]);

	const handleCopied = () => {
		setCopied(true);
		navigator.clipboard.writeText(shareableURl);
	};

	useEffect(() => {
		console.log({ copied });
		let timer: any;
		if (copied) {
			timer = setTimeout(() => {
				setCopied(false);
			}, 3000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [copied]);

	return (
		<div className=" lg:ml-2 pt-10 pb-10 mb-7 pr-5 xsm:px-5 xl:pl-8">
			<div className="xsm:order-last lg:order-first">
				<h1 className="uppercase text-primary text-4xl">{data?.name}</h1>
				<p className="text-gray-600 mt-1 mb-5 xl:text-lg lg:text-base">
					{data?.sales_description_long}
				</p>

				{/* Image Section */}
				<div className="flex align-start justify-between ">
					<div className="mb-10 xl:w-96 lg:w-72 xsm:w-full">
						<img
							style={{ margin: "auto", width: 400, height: "auto" }}
							src={image}
							alt={"product-" + data?.name}
							className="object-cover object-center"
						/>
					</div>
					{images?.length > 0 && (
						<div>
							<p className="text-gray-600">Product Images</p>
							<div className="flex items-center justify-start gap-4">
								{images?.map((img, index) => (
									<img
										key={index}
										src={`${S3_IMAGES_LINK}/${img}`}
										alt={"product-" + data?.name}
										width={100}
										height={100}
										className="border-2"
										onClick={() => onImageClick(img as string)}
									/>
								))}
							</div>
						</div>
					)}
				</div>

				{/* Actions Section */}
				<div className="flex items-center justify-between gap-4 w-5/6">
					<div
						className="relative cursor-pointer flex-grow px-4 py-1.5 rounded-md flex items-center justify-between"
						style={{ backgroundColor: NAVIGATION_BACKGROUND_COLOR }}
					>
						{specSheetLink && !specSheetLoading ? (
							<a
								href={
									specSheetLoading ? "javascript:void(0)" : specSheetLink ?? "#"
								}
								download={data?.spec_sheet || data?.name}
								target={Boolean(specSheetLink) ? "_blank" : "_self"}
								rel="noreferrer"
							>
								<div className="">Download Data Sheet</div>
							</a>
						) : !specSheetLink && specSheetLoading ? (
							<div className="">Data Sheet Loading</div>
						) : (
							<div className="">Data Sheet Not Available</div>
						)}
					</div>
					<QuoteModal>
						<button
							className="flex-grow px-4 py-1 rounded-md flex items-center justify-between gap-4 border-0 outline-0"
							style={{ backgroundColor: NAVIGATION_BACKGROUND_COLOR }}
						>
							<p className="flex-grow-1">Contact for Quote</p>
							<FaLongArrowAltRight
								className="align-self-center"
								fontSize={"26px"}
							/>
						</button>
					</QuoteModal>
					<div className="flex item-center gap-4 flex-grow relative">
						<Tooltip
							key={copied ? 1 : 0}
							title="Link Copied"
							getPopupContainer={(triggerNode) => triggerNode}
							open={copied}
							trigger={["click"]}
							showArrow
							placement="top"
							mouseLeaveDelay={0}
							className="text-center"
							overlayClassName="bg-dark max-w-max mx-auto px-3 py-2 rounded-lg text-white capitalize top-[-55px]"
						>
							<div
								className="flex items-center gap-1 cursor-pointer"
								onClick={handleCopied}
							>
								<FiShare2 fontSize={"20px"} />
								<p>Share</p>
							</div>
						</Tooltip>
						<LinkedinShareButton
							url={shareableURl}
							className="focus:outline-none"
						>
							<LinkedInIcon fontSize={"26px"} color="grey" />
						</LinkedinShareButton>
						{/* <EmailShareButton url={shareableURl} className="focus:outline-none" subject={`Gigatech Product | ${data?.name}`} body={shareableURl}>
                <EmailIcon fontSize={"26px"} color="grey" />
              </EmailShareButton> */}
						<a
							href={`mailto:?subject=Gigatech Product | ${data?.name}&body=${shareableURl}`}
						>
							<EmailIcon fontSize={"26px"} color="grey" />
						</a>
					</div>
				</div>

				{/* Specs Section */}
				<div className="mt-10">
					<ul className="flex flex-wrap text-sm font-medium text-center gap-2">
						<li className="me-2">
							<a
								href="#specifications"
								className={`inline-block px-5 py-2 uppercase ${
									activeTab === "specifications"
										? "bg-gradient-to-t from-dark to-dark text-white"
										: ""
								}`}
								onClick={() => setActiveTab("specifications")}
							>
								Specifications
							</a>
						</li>
						<li className="me-2">
							<a
								href="#related_products"
								className={`inline-block px-5 py-2 uppercase ${
									activeTab === "related_products"
										? "bg-gradient-to-t from-dark to-dark text-white"
										: ""
								}`}
								onClick={() => setActiveTab("related_products")}
							>
								Related Products
							</a>
						</li>
						<li className="me-2">
							<a
								href="#warranty"
								className={`inline-block px-5 py-2 uppercase ${
									activeTab === "warranty"
										? "bg-gradient-to-t from-dark to-dark text-white"
										: ""
								}`}
								onClick={() => setActiveTab("warranty")}
							>
								Warranty
							</a>
						</li>
					</ul>
				</div>

				{renderTab[activeTab as keyof typeof renderTab]}
			</div>
		</div>
	);
};
export default ProductDetails;
