import React, { useState } from "react";
import temp from "../images/temp.jpg";
import { IAttribute, ICategory } from "../types";
import useAttribute from "../hooks/useAttribute";

type Props = {
	image?: string;
	label?: string;
	tags?: ICategory["tags"];
	category: ICategory;
};

const Categories = ({ image, label, tags, category }: Props) => {
	return (
		<div className="flex  p-2 mb-2">
			{/* image */}
			<div className=" w-1/4">
				<img style={{ height: "100%" }} src={image} alt="temp.png" />
			</div>
			{/* text */}
			<div className="flex-col ml-5">
				<h1 className="uppercase font-bold text-black-700 text-2xl">{label}</h1>
				{tags?.map((item, index) => {
					return (
						<Tag category={category} index={index} item={item} key={index} />
					);
				})}
			</div>
		</div>
	);
};

export default Categories;

interface ITagProps {
	item: {
		label?: string;
		values?: IAttribute[];
	};
	category: ICategory;
	index: number;
}

const Tag = ({ item, category, index }: ITagProps) => {
	const { onAttributeClick } = useAttribute();

	return (
		<div className="mb-2" key={(item?.label ?? "") + index}>
			<p className="uppercase font-bold text-black-700 text-sm mb-1">
				{item?.label}
			</p>
			<div className="flex flex-wrap w-full">
				{item.values?.map((attr) => (
					<button
						key={attr._id}
						className="border px-1 ml-1 mb-1 cursor-pointer"
						onClick={() =>
							onAttributeClick(
								{
									attrName: attr.name,
									valueId: attr._id,
									valueSlug: attr.slug,
								},
								category?.abbreviation,
							)
						}
					>
						{attr.altValue || attr.value}
					</button>
				))}
			</div>
		</div>
	);
};
