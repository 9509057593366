import React, { useState } from "react";
import { css, cx } from "@emotion/css";
import {
  FaListUl,
  FaBoxes,
  FaBookOpen,
  FaTools,
  FaSearch,
} from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { IconContext } from "react-icons";
import {
  NAVIGATION_BACKGROUND_COLOR,
  NAVIGATION_LINKS_BORDER_COLOR,
  RED,
  TEXT_COLOR,
} from "../../colors";
import { Items } from "../../interfaces";
import { useHistory } from "react-router-dom";
import About from "../../images/menu-01.svg";
import Contact from "../../images/contact-phone.svg";
import { useMenu } from "../../hooks/useQueryHooks";
import { IMenu } from "../../types";
import MenuItem from "./MenuItem";

export const MAIN_LINK = process.env.REACT_APP_MAIN_SITE_URL as string;
// const MAIN_LINK = "http://3.86.233.27";

interface Props {}

const NavigationBar: React.FC = () => {

  const { data } = useMenu();

  const menu = data as IMenu;

  return (
    <nav
      style={{
        height: 60,
        display: "block",
        backgroundColor: NAVIGATION_BACKGROUND_COLOR,
      }}
      className={cx("lg:visible invisible", css``)}
    >
      <IconContext.Provider
        value={{ className: "mr-2", size: "16px", style: { marginBottom: 2 } }}
      >
        <ul
          className={cx(
            css`
              background-color: ${NAVIGATION_BACKGROUND_COLOR};
              color: ${TEXT_COLOR};
              height: 100%;
              font-size: 16px;
              @media screen and (min-width: 1240px) {
                width: 1240px;
              }
              & li {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                transition: all 0.1s;
                border-top: 3px solid ${NAVIGATION_BACKGROUND_COLOR};
                &:hover {
                  background-color: rgba(255, 255, 255, 0.8);
                  background: rgba(255, 255, 255, 0.8);
                  background: -webkit-linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.2) 100%
                  );
                  background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.2) 100%
                  );
                  border-top: 3px solid ${RED};
                  color: #333333;
                }
                & a {
                  /* text-align: center; */
                  flex: 1;
                  display: flex;
                  justify-content: center;
                }
                & p {
                  margin-bottom: 2px;
                }
              }
              @media screen and (min-width: 1250px) {
                width: 1288px;
                padding: 0 1.5rem;
              }
            `,
            "font-bold flex bg-red-200 mx-auto px-7"
          )}
        >
          {menu?.menuItems?.map((menuItem) => (
            <MenuItem key={menuItem?._id} data={menuItem} />
          ))}
        </ul>
      </IconContext.Provider>
    </nav>
  );
};

export default NavigationBar;
