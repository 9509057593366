import { css, cx } from "@emotion/css";
import React, { useEffect, useState } from "react";
import "../App.css";
import useDebounce from "../hooks/useDebouce";
import { useSearchParams } from "../hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { useProductContext } from "../context/ProductContext";
import { FaHome } from "react-icons/fa";

interface ProductSelectionProps {
	goToHome: () => void;
}

const ProductSelection: React.FC<ProductSelectionProps> = ({ goToHome }) => {
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 300);
	const { handlePageTitle } = useProductContext();
	const searchParams = useSearchParams();
	const history = useHistory();

	useEffect(() => {
		const val = searchParams.get("query");
		if (val && !search) {
			setSearch(val);
		}
	}, [searchParams]);

	useEffect(() => {
		if (!debouncedValue) return;

		history.push({
			pathname: "/search",
			search: `?query=${debouncedValue}`,
		});
	}, [debouncedValue]);

	useEffect(() => {
		if (history.location.pathname.includes("/search")) return;

		setSearch("");
	}, [history.location]);
	return (
		<div
			className={cx(
				"bg-dark h-48 lg:h-40 xsm:flex xsm:flex-col xsm:items-center xsm:h-30 xsm:justify-center  md:pt-0",
				css`
					margin-top: 75px;
					@media screen and (min-width: 425px) {
						margin-top: 90px;
					}
					@media screen and (min-width: 768px) {
						margin-top: 105px;
					}
					@media screen and (min-width: 1024px) {
						margin-top: 0;
					}
				`,
			)}
		>
			<div className="mt-2">
				<FaHome
					fontSize={"30px"}
					className="text-white hover:text-red-700 cursor-pointer"
					onClick={goToHome}
				/>
			</div>
			<h1 className=" text-white text-2xl px-5 py-4">Product Selection</h1>
			<div className="w-11/12 mx-auto">
				<input
					placeholder="Search Products"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					className="w-full px-3 py-1.5"
				/>
			</div>
		</div>
	);
};
export default ProductSelection;
