import React from "react";
import CompanyInfo from "./CompanyInfo";
import Contact from "./Contact";
import { css, cx } from "@emotion/css";
import { CONTACT_ADDRESS_COLOR } from "../../colors";
import { AiOutlineArrowUp } from "react-icons/ai";
const Container: React.FC = () => {
  return (
    <div
      className={cx(
        "pl-4 pr-6 pb-5 pt-6",
        css`
          @media screen and (min-width: 1290px) {
            width: 1290px;
          }
          margin: 0 auto;
        `,
      )}
    >
      <div
        className={css`
          display: grid;
          grid-template-columns: 1fr;
          @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 34px;
          }
          @media screen and (min-width: 1300px) {
            grid-template-columns: 500px 383px 383px;
          }
        `}
      >
        <CompanyInfo />
        <div
          className={css`
            display: grid;
            grid-template-columns: 1fr;
            @media screen and (min-width: 768px) {
              grid-template-columns: repeat(1, 1fr);
              /* grid-column-gap: 10px; */
            }
            @media screen and (min-width: 1300px) {
              grid-template-columns: 383px 383px;
            }
          `}
        >
          <div className="mt-1">
            <Contact
              heading={"GigaTech Products Inc."}
              email={"sales@gigatechproducts.com"}
              phone={"(877) 744-4283 / (949) 202-4292"}
              contact={"18001 Cowan, Suite A|Irvine, CA 92614|USA"}
            />
          </div>
          <div className="mt-1">
            <Contact
              heading={"GigaTech Products B.V."}
              email={"sales.emea@gigatechproducts.com"}
              phone={"+31 (0)20 2154077"}
              contact={"Kabelweg 57|1014 BA Amsterdam|Netherlands"}
            />
          </div>
        </div>
      </div>
      <p style={{ color: CONTACT_ADDRESS_COLOR }}>
        © GigaTech Products – 2021. All rights reserved.
      </p>
    </div>
  );
};

export default Container;
