import React, { ReactNode, ReactPortal, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { IProduct } from "../../types";
interface OtherProductProps {
  data?: IProduct | undefined,
  heading: string;
  description?: string;
  isItem?: boolean;
  id?: string;
  slug?: string;
  variant?: string;
  onClickHandler?: any
}

const OtherProduct: React.FC<OtherProductProps> = ({
  data,
  heading,
  description,
  isItem,
  onClickHandler,
  variant,
  id,
  slug,
}) => {
  const [visible, setVisible] = useState<boolean | undefined>(false);
  let toRender: ReactNode | ReactPortal;

  if (isItem) {
    toRender = visible ? (
      <div
        className="w-80 bg-white shadow-xl rounded p-4 cursor-pointer"
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onClick={() => variant === "secondary" ? onClickHandler?.(data) : onClickHandler?.(slug as string, id as string)}
      >
        <h1 className="uppercase text-center text-primary text-xl mb-4">
          {heading}
        </h1>
        <p>{description}</p>
      </div>
    ) : null;
  } else {
    toRender = null;
  }

  return (
    <div className="relative">
      {/* {toRender} */}
      <Tippy
        render={(attrs) => (
          <div className="absolute" {...attrs}>
            {toRender}
          </div>
        )}
        interactive={true}
        // placement="left"
        offset={[-100, 40]}
        zIndex={9999}
        popperOptions={{
          strategy: "fixed",
        }}
      >
        <div
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          onClick={() => variant === "secondary" ? onClickHandler?.(data) : onClickHandler?.(slug as string, id as string)}
          className="bold border-b-2 border-gray-100 cursor-pointer hover:font-bold hover:bg-hover xl:pl-10 lg:pl-5 pr-5 xsm:px-4 overflow-visible"
        >
          <h1 className="py-3 text-lg text-gray-600 uppercase">{heading}</h1>
        </div>
      </Tippy>
    </div>
  );
};
export default OtherProduct;
