export interface IMenu {
	_id?: string;
	name?: string;
	menuItems?: IMenuItem[];
	status?: string;
	createdAt?: string;
	updatedAt?: string;
	__v?: number;
}

export interface IMenuItem {
	name: string;
	link?: string;
	iconName?: string;
	subMenus?: [
		{
			name: string;
			link?: string;
		},
	];
	_id?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface ICategory {
	_id: string;
	name: string;
	createdAt?: string;
	updatedAt?: string;
	label?: string;
	imageName?: string;
	tags?: {
		label?: string;
		values?: Array<IAttribute>;
	}[];
	__v?: number;
	abbreviation: string;
}

export interface IAttribute {
	_id: string;
	category: string | ICategory;
	createdAt?: string;
	name: string;
	label: string;
	slug: string;
	show: boolean;
	updatedAt?: string;
	value: string;
	altValue?: string;
	order: number;
	sortNumberCategory?: string;
}

export interface IDropdownData {
	_id: string;
	name: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	filters: IDropDownFilter[];
	__v: number;
}

export interface IDropDownFilter {
	_id: string;
	label: string;
	order: number;
	createdAt: string;
	updatedAt: string;
	category: ICategory;
	attributes: IDropDownFilterAttribute[];
}

export interface IDropDownFilterAttribute {
	_id: string;
	name: string;
	order: 0;
	value: IAttribute[];
}

export interface IProduct {
	_id: string;
	sort_number_product?: string;
	sort_number_category?: string;
	name: string;
	sales_description_short: string;
	sales_description_long: string;
	purchase_description: string;
	purchase_price: string;
	br_yellow: string;
	br_black: string;
	bb_red: string;
	var_blue: string;
	corp_green: string;
	gt_list: string;
	manufacture_list: string;
	reorder_point: string;
	item_note_1: string;
	item_note_2: string;
	attributes: IAttribute[];
	category: ICategory;
	csv: any;
	product_wt: string;
	product_dims: string;
	gt_class: string;
	gt_sub_class: string;
	gt_sub_sub_class: string;
	gt_marketing_id: string;
	primary_substitute: string;
	gt_internal_number: string;
	coding: string;
	product_note: string;
	restricted_note: string;
	label_line_1: string;
	label_line_2: string;
	label_line_secondary_1?: string;
	label_line_secondary_2?: string;
	image_1: string;
	image_2?: string;
	image_3?: string;
	spec_sheet: string;
	image_exists: boolean;
	status: ProductStatusType;
}

export const ProductStatus = {
	DRAFT: "DRAFT",
	PENDING: "PENDING",
	PUBLISHED: "PUBLISHED",
} as const;

export type ProductStatusType = keyof typeof ProductStatus;

export const ATTRIBUTE_NAMES = {
	"MANUF COMP": "MANUF COMP",
	"MANUF CAT": "MANUF CAT",
} as const;

export type AttributeNamesType = keyof typeof ATTRIBUTE_NAMES;
