import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetProductByID } from "../hooks/useQueryHooks";
import { useProductContext } from "../context/ProductContext";
interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { pageTitle } = useProductContext();
  const history = useHistory();
  const location = useLocation();
  const category = location.pathname.split("/")[1];
  const attribute = location.pathname.split("/")[2];
  const variant = location.pathname.split("/")[3];
  const productId = location.pathname.split("/")[4];
  const paths = [category, attribute, variant].filter(Boolean);

  const breadcrumbs = paths.map((el) => {
    const sanitized = el.replaceAll("_", " ").split("-")[0];
    return {
      name: sanitized,
      slug: el,
    };
  });

  const { data, isLoading } = useGetProductByID(category, productId, {
    enabled: Boolean(productId),
  });
  const handleClick = (location: string, order: number) => {
    switch (order) {
      case 1:
        history.push(`/${location}`);
        break;
      case 2:
        history.push(`/${category}/${location}`);
        break;
      case 3:
        history.push(`/${category}/${attribute}/${location}`);
        break;
      default:
        break;
    }
    return;
  };
  return (
    <div className="lg:bg-header xl:pl-10 lg:pl-5 py-5.5 xsm:bg-white xsm:mx-3 lg:mx-0">
      <h1 className="uppercase font-bold text-gray-700 text-xl">{pageTitle}</h1>
      <div className="lg:flex text-primary mt-1 xsm:hidden ">
        {breadcrumbs?.map((breadcrumb, i) => (
          <div className="uppercase pl-1">
            <span
              onClick={() => handleClick(breadcrumb.slug, i + 1)}
              className="cursor-pointer"
            >
              {breadcrumb?.name}
            </span>
            {i + 1 < breadcrumbs.length && " > "}
          </div>
        ))}
        {data?._id && (
          <div className="uppercase pl-1">
            {" > "}
            <span className="cursor-pointer">{data?.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
