import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { useGetProductsByAttributeIds } from "../../hooks/useQueryHooks";
import loader from "../../images/loader.gif";
import { ATTRIBUTE_NAMES, IProduct } from "../../types";
import { getProductNamesByAttribute } from "../../utils/getFilteredProducts";
import OtherProduct from "./OtherProduct";
import { extractSearchParams } from "../../utils/extractSearchParams";

type Props = {};

const Variants = () => {
	const location = useLocation();
	const history = useHistory();
	const category = location.pathname.split("/")[1];
	const attribute = location.pathname.split("/")[2];
	const variant = location.pathname.split("/")[3];
	const [products, setProducts] = useState<IProduct[]>([]);

	const queryIds = Object.values(extractSearchParams(location.search));
	const attributeId = attribute.split("-")[1];
	const variantId = variant.split("-")[1];

	const {
		data: response,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		refetch,
	} = useGetProductsByAttributeIds(
		category,
		JSON.stringify([...queryIds, attributeId, variantId].filter(Boolean)),
		undefined,
		{
			onSuccess: (data: { pages: any[] }) => {
				// console.log({ data });
				const newData = data?.pages?.flatMap((page) => {
					const processedBatch = getProductNamesByAttribute(
						page.products,
						ATTRIBUTE_NAMES["MANUF CAT"],
						variant.split("-")[0],
					);
					return processedBatch;
				});

				setProducts((prev) => [...newData]);
			},
		},
	);

	const onClick = (slug: string, id: string) => {
		history.push(`/${category}/${attribute}/${variant}/${id}`);
	};

	useEffect(() => {
		if (response?.pages.length === 0) return;

		refetch();
	}, [response?.pages.length]);

	return (
		<div className="relative min-h-screen">
			{/* {category ? (
        <h1 className="font-bold text-gray-700 text-xl xl:pl-10 lg:pl-5 pr-5 xsm:px-4 capitalize">
          {category?.split("-").join(" ")}
        </h1>
      ) : null} */}
			{(isLoading || isFetching) && (
				<div className="flex items-center justify-center">
					<img
						src={loader}
						alt="loader"
						className="w-16 absolute top-72 left-96"
					/>
				</div>
			)}
			{products?.length > 0 && (
				<InfiniteScroll
					dataLength={products.length || 0}
					next={fetchNextPage}
					hasMore={hasNextPage as boolean}
					endMessage={<></>}
					loader={
						<h4 className="xl:pl-10 lg:pl-5 pr-5 xsm:px-4 text-gray-700 py-2">
							Loading ...
						</h4>
					}
				>
					{products?.map((item) => {
						return (
							<div key={item._id}>
								<OtherProduct
									heading={item.name}
									description={item.sales_description_long}
									isItem={true}
									onClickHandler={onClick}
									id={item._id}
									slug={item._id}
								/>
							</div>
						);
					})}
				</InfiniteScroll>
			)}
		</div>
	);
};

export default Variants;
