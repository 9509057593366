import React from "react";
import Logo from "../../images/GT-logo-footer-new.svg";
import Certificates from "../../images/footer_certs.svg";
import { css, cx } from "@emotion/css";
import { FaLinkedinIn, FaFacebook, FaTwitter } from "react-icons/fa";
import { CONTACT_LINKS_HOVER, FOOTER_BACKGROUND_COLOR, LINK_HOVER, RED } from "../../colors";
import { IconContext } from "react-icons";
import useWindowSize from "../../hooks/useWindowSize";
import { MAIN_LINK } from "../Navigation/NavigationBar";
// const MAIN_LINK = "https://www.gigatechproducts.com";
// const MAIN_LINK = "http://3.86.233.27";

const CompanyInfo: React.FC = () => {
  const { width } = useWindowSize();
  return (
    <div
      className="flex flex-col items-center text-white mt-1.5"
      style={{ marginBottom: 34, width: width && width > 1300 ? 500 : "auto" }}
    >
      <div className="overflow-hidden self-start">
        <a href="https://www.gigatechproducts.com/">
          <img src={Logo} alt="Logo" style={{ width: 400.95, height: 57.47 }} />
        </a>
      </div>
      <div
        className={cx(
          "flex flex-col md:flex-row items-center mt-10 w-full self-start pl-1",
          css`
            @media screen and (min-width: 1300px) {
              width: 413px;
              justify-content: space-between;
            }
          `,
        )}
      >
        <div
          className={cx(
            "w-full mb-4 mt-1 mx-1",
            css`
              display: flex;
              flex-direction: column;
              align-items: center;
              @media screen and (min-width: 768px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 2;
                grid-gap: 0px;
              }
              @media screen and (min-width: 1300px) {
                width: 284px;
                align-items: start;
                align-content: start;
              }
              & > a {
                padding: 10px 12px;
                font-size: 16px;
                width: 100%;
                text-align: center;
                line-height: 20px;
                border-bottom: 3px solid ${FOOTER_BACKGROUND_COLOR};
                &:hover {
                  border-bottom: 3px solid ${LINK_HOVER};
                }
                @media screen and (min-width: 768px) {
                  padding: 2px 5px;
                  text-align: start;
                }
                @media screen and (min-width: 1300px) {
                  width: 130px;
                }
              }
            `,
          )}
        >
          <a href={`${MAIN_LINK}/warranty/`}>Warranty</a>
          <a
            href={`${MAIN_LINK}/terms-conditions/`}
            style={{ width: !!width && width < 768 ? 200 : 153 }}
          >
            Terms & Conditions
          </a>
          <a href={`${MAIN_LINK}/return-policy/`}>Return Policy</a>
          <a href={`${MAIN_LINK}/privacy-policy/`}>Privacy Policy</a>
        </div>
        <IconContext.Provider value={{ size: width && width < 768 ? "25px" : "14px" }}>
          <div
            className={cx(
              "flex flex-row md:self-start mt-1.5",
              css`
                & > a {
                  background-color: ${RED};
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 10%;
                  transition: all 0.3s;
                  &:hover {
                    background-color: ${CONTACT_LINKS_HOVER};
                    transform: scale(1.1);
                  }
                  @media screen and (min-width: 768px) {
                    width: 28px;
                    height: 28px;
                  }
                }
              `,
            )}
          >
            <a href="https://www.linkedin.com/company/gigatech-products/">
              <FaLinkedinIn />
            </a>
            <a href="https://www.facebook.com/GigaTechProducts/" className="mx-2">
              <FaFacebook />
            </a>
            <a href="https://twitter.com/GigatechProduct">
              <FaTwitter />
            </a>
          </div>
        </IconContext.Provider>
      </div>
      <div className={cx("overflow-hidden self-start md:mt-1 mt-7")}>
        <img src={Certificates} alt="Certificates" style={{ width: 417.97, height: 30.94 }} />
      </div>
    </div>
  );
};

export default CompanyInfo;
