import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { ManufacturerContext } from "../context/ManufacturerContext";
import ReactTooltip from "react-tooltip";

interface DropdownProps {
  title: string | undefined;
  label?: string;
  icon: any;
  expanded?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  icon,
  label,
  expanded,
}) => {
  const { localGtClassId, loading } = useContext(ManufacturerContext);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [localGtClassId]);
  return (
    <div>
      <div
        className={`cursor-pointer ${
          expanded ? "bg-primary" : ""
        } flex items-center justify-between rounded p-2 mx-2 text-white mb-1 `}
      >
        <div className="flex justify-center items-center">
          {expanded ? (
            <span className="text-xs text-white mr-3">
              <FontAwesomeIcon icon={faCircle} />
            </span>
          ) : (
            <span className="text-xs text-primary mr-3">
              <FontAwesomeIcon icon={faCircle} />
            </span>
          )}
          {/* Done as such to fulfill client needs */}
          <p className="font-bold unselectableText text-md mr-2 capitalize">
            {label}
          </p>
        </div>
        {expanded ? (
          <span data-tip="Reset Filter" className="ml-2">
            <ReactTooltip />
            <FontAwesomeIcon icon={faRedoAlt} pulse={loading} />
          </span>
        ) : (
          <span>
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </div>
    </div>
  );
};
export default Dropdown;
