import React, { useState } from "react";
import Navigation from "../Navigation";
import Header from "../Header";
import Popup404 from "../404Popup";
import Footer from "../Footer/index";
import Sidebar from "../Sidebar";
import ScrollToTop from "react-scroll-to-top";
import ArrowTop from "../../images/scroll-top.svg";
import Hero from "../../components/Hero";

type Props = {
  children?: React.ReactChild;
};

const Layout = ({ children }: Props) => {
  const [popup, setPopup] = useState(false);
  return (
    <div>
      <Header
        setItems={() => {}}
        setSidebarVisible={() => {}}
        sidebarVisible={false}
      />
      <div className="h-full mainContainer--width mainContainer--margins">
        <div className="flex xsm:flex-col lg:flex-row sidebar-main-height mt-10">
          <Sidebar />
          <div className="lg:flex-1 lg:ml-0">
            <Hero />
            {children}
          </div>
          <ScrollToTop
            smooth
            // component={<HiArrowUp style={{ fontSize: 24, fontWeight: "bold" }} />}
            component={
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={ArrowTop} style={{ width: 18.25, height: 20.5 }} />
              </div>
            }
            style={{
              backgroundColor: "rgb(21,	21,	21)",
              color: "white",
              padding: "8px",
              boxShadow: "none",
              right: 10,
              bottom: 10,
              fontWeight: "bold",
              outline: "none",
            }}
            // className="px-3 py-2"
            color={"white"}
          />
        </div>
        {popup && <Popup404 setPopup={setPopup} />}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
