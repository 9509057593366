import React from "react";
import { IProduct } from "../types";

type Props = {
	data: IProduct | undefined;
};

const Specifications = ({ data }: Props) => {
	const mutatedAttributes = data?.attributes
		.filter((el: any) => Boolean(el?.show))
		.sort((a, b) => a.order - b.order);

	return (
		<div className="relative overflow-x-auto">
			<table className="table-fixed w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
				<tbody>
					{mutatedAttributes?.map((attr: any, i: number) => {
						const val =
							attr.booleanValue === false
								? "NO"
								: attr.booleanValue === null
								? attr.value
								: "YES";
						return (
							<tr key={i} className="odd:bg-white even:bg-gray-100 border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
								>
									{attr?.label || attr?.name}:
								</th>
								<td className="px-6 py-4">{val}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Specifications;
