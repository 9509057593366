import React, { useEffect, useState } from "react";
import Logo from "../../images/GT-logo-new.svg";
import { HiOutlineSearch } from "react-icons/hi";
import { css, cx } from "@emotion/css";
import {
  FaListUl,
  FaBoxes,
  FaBookOpen,
  FaTools,
  FaSearch,
} from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { RED } from "../../colors";
import useWindowSize from "../../hooks/useWindowSize";
import { Items } from "../../interfaces";
import Contact from "../../images/contact-phone.svg";
import { useHistory, useLocation } from "react-router-dom";
import InputSearchIcon from "../../images/search-solid.svg";
// import InputSearchIcon from "../../images/search_bar.svg";
// import Magnifier from "../../images/search_bar.svg";
interface Props {
  setItems: React.Dispatch<React.SetStateAction<Items[]>>;
  sidebarVisible: boolean;
  setSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchProducts: React.FC<Props> = ({
  setItems,
  sidebarVisible,
  setSidebarVisible,
}) => {
  const history = useHistory();
  const { width } = useWindowSize();
  const [menuIconHover, setMenuIconHover] = useState(false);
  const [sidebarCloseButtonHover, setSidebarCloseButtonHover] = useState(false);
  const [aboutSubmenu, setAboutSubmenu] = useState(false);
  const [productSubmenu, setProductSubmenu] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const typeParam = params.get("s");
      if (typeParam) {
        setSearchProduct(typeParam);
      }
    }
  }, [location.search]);
  // width exists and is less than 768, then width = width
  return (
    <div
      className={`${!!width && width < 1024 ? "fixed bg-white top-0" : ""}`}
      style={{ width: !!width && width > 520 ? "100%" : width }}
    >
      <div
        className={cx(
          "flex flex-col lg:flex-row md:justify-between items-center justify-center h-full pt-4 mx-auto",
          css`
            padding: 1rem 1.75rem;
            @media screen and (min-width: 1250px) {
              width: 1240px;
              padding: 1rem 0;
            }
          `
        )}
      >
        <div className={`overflow-hidden mb-2.5 lg:mb-0`}>
          <a href="https://www.gigatechproducts.com/">
            <img
              src={Logo}
              alt="Logo"
              className="cursor-pointer"
              // style={{
              //   width: "100%",
              //   height: "auto",
              //   paddingRight: 15,
              //   display: width && width < 768 ? "none" : "block",
              // }}
              style={{
                width:
                  !!width && width < 400
                    ? "220px"
                    : !!width && width <= 768
                    ? "312px"
                    : "100%",
                height: "auto",
                paddingRight: 15,
              }}
            />
          </a>
        </div>
        {/* <button className="bg-primary px-3 py-2 rounded-md flex items-center justify-between w-1/6 focus:outline-none">
            <p className="text-white">Quote</p>
            <div className="bg-white py-0 px-2">
              <p>2</p>
            </div>
        </button> */}
        {/* menu icon -- start */}
        {!!width && width < 1024 && (
          <div
            className={cx(
              "flex flex-col justify-center items-center relative cursor-pointer",
              css`
                position: absolute;
                right: 33px;
                top: 19px;
                transition: all 0.3s;
                @media screen and (min-width: 750px) {
                  top: 27px;
                  right: 68px;
                }
                & span {
                  position: absolute;
                  height: 15px;
                  border-bottom: 3px solid;
                  transition: all 0.3s;
                  width: 30px;
                }
              `
            )}
            onClick={() => setSidebarVisible(true)}
            onMouseEnter={() => setMenuIconHover(true)}
            onMouseLeave={() => setMenuIconHover(false)}
          >
            <span
              className={cx(css`
                transform: translate(0, ${menuIconHover ? "7px" : "3px"});
              `)}
            >
              -
            </span>
            <span
              className={cx(
                css`
                  transform: translate(0, 11px);
                `
              )}
            >
              -
            </span>
            <span
              className={cx(
                css`
                  transform: translate(0, ${menuIconHover ? "15px" : "19px"});
                `
              )}
            >
              -
            </span>
          </div>
        )}
        {/* menu icon -- ends */}
      </div>
      {/* {sidebarVisible && ( */}
      {/* <div className={"w-full h-full fixed top-0 left-0 bg-opacity-40 flex z-50 min-h-screen"}> */}
      <div
        className={
          sidebarVisible
            ? "w-full h-full fixed z-40 top-0 flex-col  transition-all duration-500  ease-in-out transform  translate-x-0"
            : "w-full fixed z-40 top-0 flex-col  transition-all duration-500 ease-in-out transform  translate-x-full"
        }
      >
        <div
          className={cx(`w-full h-screen absolute top-0 left-0 z-30`)}
          onClick={() => {
            setSidebarVisible(false);
            setSidebarCloseButtonHover(false);
          }}
        />
        <div
          className={cx(
            "absolute z-50",
            css`
              right: 300px;
              width: 300px;
              position: fixed;
              top: -4px;
              height: 100%;
              min-height: 100%;
              max-width: 100%;
              box-sizing: border-box;
              transform: translate3d(100%, 0, 0);
              transition: right 0.5s ease;
              background-color: white;
              padding: 30px;
            `
          )}
        >
          <div
            className={cx(
              "absolute  text-xl cursor-pointer z-50",
              css`
                transition: all 0.3s;
                width: 16px;
                right: 18px;
              `
            )}
            onClick={() => {
              setSidebarVisible(false);
              setSidebarCloseButtonHover(false);
            }}
            onMouseEnter={() => setSidebarCloseButtonHover(true)}
            onMouseLeave={() => setSidebarCloseButtonHover(false)}
          >
            <span
              className={css`
                width: 16px;
                border-bottom: 4px solid #333333;
                transition: transform 0.3s;
                transform: rotate3d(
                  0,
                  0,
                  1,
                  ${sidebarCloseButtonHover ? "0deg" : "45deg"}
                );
                position: absolute;
              `}
            />
            <span
              className={css`
                width: 16px;
                border-bottom: 4px solid #333333;
                transition: transform 0.3s;
                transform: rotate3d(
                  0,
                  0,
                  1,
                  ${sidebarCloseButtonHover ? "0deg" : "-45deg"}
                );
                position: absolute;
              `}
            />
          </div>
          <ul
            className={cx(
              "font-bold mt-8",
              css`
                & a {
                  &:hover {
                    color: ${RED};
                  }
                }
              `
            )}
            style={{ fontSize: 18 }}
          >
            <li>
              <div className="flex justify-between">
                <a href="" className="flex flex-1 items-center mb-5">
                  <FaListUl className="mr-2.5" />
                  About
                </a>
                <AiOutlinePlus
                  className={cx(
                    "text-xl cursor-pointer",
                    css`
                      transition: transform 0.3s;
                      transform: ${aboutSubmenu
                        ? "rotateY(0deg) rotate(45deg)"
                        : ""};
                    `
                  )}
                  style={{ color: "#dddddd", fontSize: 23, fontWeight: 700 }}
                  onClick={() => {
                    setAboutSubmenu(!aboutSubmenu);
                    setProductSubmenu(false);
                  }}
                />
              </div>
              <span
                className={cx(
                  "flex flex-col pl-4",
                  css`
                    overflow: hidden;
                    height: ${aboutSubmenu ? "80px" : 0};
                    transition: height 1s;
                  `
                )}
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                <p className="mb-3" style={{ lineHeight: "20px" }}>
                  Company DNA
                </p>
                <p style={{ lineHeight: "20px" }}>Why GigaTech</p>
              </span>
              {/* )} */}
            </li>
            <li>
              <div className="flex justify-between">
                <a href="" className="flex flex-1 items-center mb-5">
                  <FaBoxes className="mr-2.5" size="20px" />
                  Products
                </a>
                <AiOutlinePlus
                  className={cx(
                    "text-xl cursor-pointer",
                    css`
                      transition: transform 0.3s;
                      transform: ${productSubmenu
                        ? "rotateY(0deg) rotate(45deg)"
                        : ""};
                    `
                  )}
                  style={{ color: "#dddddd", fontSize: 23, fontWeight: 700 }}
                  onClick={() => {
                    setProductSubmenu(!productSubmenu);
                    setAboutSubmenu(false);
                  }}
                />
              </div>
              <div
                className={cx(
                  "flex flex-col pl-4",
                  css`
                    overflow: hidden;
                    height: ${productSubmenu ? "170px" : 0};
                    transition: height 1s;
                    & > p {
                      line-height: 20px;
                      margin-bottom: 0.75rem;
                    }
                  `
                )}
                style={{ fontSize: 16, fontWeight: 400 }}
              >
                <p>Transceivers</p>
                <p>DAC</p>
                <p>Active Optic Cables</p>
                <p>Fiber Cables</p>
                <p>Copper Cable</p>
              </div>
              {/* )} */}
            </li>
            <li>
              <a href="" className="flex flex-1 items-center mb-5">
                <FaBookOpen className="mr-2.5" size="20px" />
                Resources
              </a>
            </li>
            <li>
              <a href="" className="flex flex-1 items-center mb-5">
                <FaTools className="mr-2.5" />
                Support
              </a>
            </li>
            <li>
              <a href="" className="flex flex-1 items-center mb-5">
                <span className="mr-2.5">
                  <img src={Contact} alt="" style={{ width: 16, height: 16 }} />
                </span>
                Contact
              </a>
            </li>
            <li>
              <a href="" className="flex flex-1 items-center mb-5">
                <FaSearch className="mr-2.5" />
                Product Finder
              </a>
            </li>
          </ul>
        </div>
      </div>
      {sidebarVisible && (
        <div
          className={cx(
            `bg-black w-full h-screen absolute top-0 left-0 bg-opacity-30 z-30`,
            css``
          )}
          // onClick={() => {
          //   setSidebarVisible(false);
          //   setSidebarCloseButtonHover(false);
          // }}
        />
      )}
    </div>
  );
};

export default SearchProducts;
