import React, { useState } from "react";
import { css, cx } from "@emotion/css";
import {
  FaListUl,
  FaBoxes,
  FaBookOpen,
  FaTools,
  FaSearch,
} from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { IconContext } from "react-icons";
import {
  NAVIGATION_BACKGROUND_COLOR,
  NAVIGATION_LINKS_BORDER_COLOR,
  RED,
  TEXT_COLOR,
} from "../../colors";
import { Items } from "../../interfaces";
import { useHistory } from "react-router-dom";
import About from "../../images/menu-01.svg";
import Contact from "../../images/contact-phone.svg";

export const MAIN_LINK = "https://www.gigatechproducts.com";
// const MAIN_LINK = "http://3.86.233.27";

interface Props {
  setItems: React.Dispatch<React.SetStateAction<Items[]>>;
}

const NavigationBar: React.FC<Props> = ({ setItems }) => {
  const history = useHistory();
  const [aboutSubmenu, setAboutSubmenu] = useState(false);
  const [productSubmenu, setProductSubmenu] = useState(false);
  return (
    <nav
      style={{
        height: 60,
        display: "block",
        backgroundColor: NAVIGATION_BACKGROUND_COLOR,
      }}
      className={cx("lg:visible invisible", css``)}
    >
      <IconContext.Provider
        value={{ className: "mr-2", size: "16px", style: { marginBottom: 2 } }}
      >
        <ul
          className={cx(
            css`
              background-color: ${NAVIGATION_BACKGROUND_COLOR};
              color: ${TEXT_COLOR};
              height: 100%;
              font-size: 16px;
              @media screen and (min-width: 1240px) {
                width: 1240px;
              }
              & li {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                transition: all 0.1s;
                border-top: 3px solid ${NAVIGATION_BACKGROUND_COLOR};
                &:hover {
                  background-color: rgba(255, 255, 255, 0.8);
                  background: rgba(255, 255, 255, 0.8);
                  background: -webkit-linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.2) 100%
                  );
                  background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.8) 0%,
                    rgba(255, 255, 255, 0.2) 100%
                  );
                  border-top: 3px solid ${RED};
                  color: #333333;
                }
                & a {
                  /* text-align: center; */
                  flex: 1;
                  display: flex;
                  justify-content: center;
                  height: 16px;
                }
                & p {
                  margin-bottom: 2px;
                }
              }
              @media screen and (min-width: 1250px) {
                width: 1288px;
                padding: 0 1.5rem;
              }
            `,
            "font-bold flex bg-red-200 mx-auto px-7"
          )}
        >
          <li
            className="relative"
            onMouseEnter={() => setAboutSubmenu(true)}
            onMouseLeave={() => setAboutSubmenu(false)}
            style={{ flex: 0.96 }}
          >
            <a
              href="#"
              className={cx(
                "flex items-center border-l border-r cursor-default",
                css`
                  border-left: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                  border-right: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                `
              )}
            >
              <span style={{ marginRight: 8.5 }}>
                <img
                  src={About}
                  alt=""
                  style={{ height: 16, width: 16, marginBottom: 2 }}
                />
              </span>
              <p>About</p>
            </a>

            <div
              className={cx(
                "shadow  flex-col items-start h-28",
                css`
                  /* display: "flex"; */
                  display: ${aboutSubmenu ? "flex" : "none"};
                  position: absolute;
                  background-color: white;
                  top: 58px;
                  left: 0px;
                  padding: 20px;
                  width: 300px;
                  transition: all 0.4s ease-in-out;
                  z-index: 100;
                  font-weight: 500;
                  & > a {
                    &:hover {
                      color: ${RED};
                    }
                  }
                `
              )}
              onMouseEnter={() => setAboutSubmenu(true)}
              onMouseLeave={() => setAboutSubmenu(false)}
            >
              <a href={`${MAIN_LINK}/about/company/`}>Company DNA</a>
              <a href={`${MAIN_LINK}/about/why-gigatech/`} className="pt-5">
                Why GigaTech
              </a>
            </div>
          </li>
          <li
            className={cx(
              "relative",
              css`
                flex: 1.07 !important;
              `
            )}
            onMouseEnter={() => setProductSubmenu(true)}
            onMouseLeave={() => setProductSubmenu(false)}
          >
            <a
              href="#"
              className={cx(
                "flex items-center border-r  cursor-default",
                css`
                  border-right: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                `
              )}
            >
              <span>
                <FaBoxes size="18px" />
              </span>
              <p>Products</p>
            </a>
            <div
              className={cx(
                "shadow  flex-col items-start",
                css`
                  display: ${productSubmenu ? "flex" : "none"};
                  height: 240px;
                  position: absolute;
                  background-color: white;
                  top: 58px;
                  left: 0px;
                  padding: 20px 20px;
                  width: 300px;
                  transition: all 0.4s ease-in-out;
                  z-index: 100;
                  font-weight: 500;
                  & > a {
                    &:hover {
                      color: ${RED};
                    }
                  }
                `
              )}
              onMouseEnter={() => setProductSubmenu(true)}
              onMouseLeave={() => setProductSubmenu(false)}
            >
              <a href={`${MAIN_LINK}/gigatech-transceivers/`}>Transceivers</a>
              <a href={`${MAIN_LINK}/gigatech-dac-cables/`} className="pt-5">
                DAC
              </a>
              <a href={`${MAIN_LINK}/gigatech-aoc-cables/`} className="pt-5">
                AOC
              </a>
              <a href={`${MAIN_LINK}/gigatech-cables/`} className="pt-5">
                Cables
              </a>
              <a href={`${MAIN_LINK}/gigatech-hardware/`} className="pt-5">
                Hardware
              </a>
            </div>
          </li>
          <li
            className={css`
              flex: 1.11 !important;
            `}
          >
            <a
              href={`${MAIN_LINK}/resources/`}
              className={cx(
                "flex items-center border-r ",
                css`
                  border-right: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                `
              )}
            >
              <span>
                <FaBookOpen size="18px" style={{ marginRight: 7 }} />
              </span>
              <p>Resources</p>
            </a>
          </li>
          <li
            className={css`
              flex: 1.05 !important;
            `}
          >
            <a
              href={`${MAIN_LINK}/customer-support-guide/`}
              className={cx(
                "flex items-center border-r ",
                css`
                  border-right: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                `
              )}
            >
              <span>
                <FaTools />
              </span>
              <p>Support</p>
            </a>
          </li>
          <li
            className={css`
              flex: 1.03 !important;
            `}
          >
            <a
              href={`${MAIN_LINK}/contact/`}
              className={cx(
                "flex items-center border-r ",
                css`
                  border-right: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                `
              )}
            >
              <span className="mr-3 mb-0.5">
                <img src={Contact} alt="" style={{ width: 15, height: 15 }} />
              </span>
              <p>Contact</p>
            </a>
          </li>
          <li
            className={css`
              border-top: 3px solid ${RED} !important;
              flex: 1.27 !important;
              cursor: pointer;
            `}
          >
            <a
              className={cx(
                "flex items-center border-r cursor-pointer",
                css`
                  border-right: 2px solid ${NAVIGATION_LINKS_BORDER_COLOR};
                `
              )}
              onClick={() => {
                setItems([]);
                history.push(`/`);
              }}
            >
              <span>
                <FaSearch size="16px" />
              </span>
              <p>Product Finder</p>
            </a>
          </li>
        </ul>
      </IconContext.Provider>
    </nav>
  );
};

export default NavigationBar;
