const Warranty = () => {
	return (
		<div className="my-6 px-6">
			<p>
				GigaTech Products proudly backs all Transceivers, DAC, AOC, Fiber, and
				Copper cables with a Lifetime Warranty.
			</p>
			<a
				className="text-blue-500"
				href="https://www.gigatechproducts.com/warranty"
			>
				- https://www.gigatechproducts.com/warranty/
			</a>
			<p className="mt-3">
				For pre-sales and post-sales support, please contact our trained and
				certified technical experts through our Support Portal.
			</p>
			<a
				className="text-blue-500"
				href="https://support.gigatechproducts.com/support/tickets/new"
			>
				- https://support.gigatechproducts.com/support/tickets/new
			</a>
		</div>
	);
};

export default Warranty;
