import React from "react";
import Categories from "../../components/Categories";
import { useGetFilters } from "../../hooks/useQueryHooks";

interface indexProps {}

const Index: React.FC<indexProps> = () => {
	const { isLoading, data } = useGetFilters();
	const categories = data?.filters?.map((el) => el.category);
	const uri = process.env.REACT_APP_AWS_IMAGES_CLOUDFRONT_URI;

	return (
		<>
			{isLoading && <p>Loading...</p>}
			{!isLoading &&
				categories?.map((item, index) => (
					<Categories
						key={index}
						label={item?.label}
						image={`${uri}/${item?.imageName}`}
						tags={item?.tags}
						category={item}
					/>
				))}
		</>
	);
};

export default Index;
