import React from "react";
import { FOOTER_BACKGROUND_COLOR } from "../../colors";
import Container from "./Container";
import useWindowSize from "../../hooks/useWindowSize";
const Index: React.FC = () => {
	const { width } = useWindowSize();
	return (
		<div
			className="mt-10"
			style={{
				backgroundColor: FOOTER_BACKGROUND_COLOR,
				height: !!width && width >= 1305 ? 320.42 : "auto",
			}}
		>
			<Container />
		</div>
	);
};

export default Index;
