import React from "react";
import { Items } from "../../interfaces";
import NavigationBar from "./NavigationBar";
import SearchProducts from "./SearchProducts";
import useWindowSize from "../../hooks/useWindowSize";

interface Props {
  setItems: React.Dispatch<React.SetStateAction<Items[]>>;
  sidebarVisible: boolean;
  setSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({
  setItems,
  sidebarVisible,
  setSidebarVisible,
}) => {
  const { width } = useWindowSize();

  return (
    <div>
      <SearchProducts
        setItems={setItems}
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
      />
      {!!width && width >= 1024 ? <NavigationBar /> : <></>}
    </div>
  );
};

export default Header;
