import { QueryClient, QueryClientProvider } from "react-query";
import Router from "./router/Router";
import { ProductContextProvider } from "./context/ProductContext";
import "antd/dist/antd.css";
import "./App.css";
import "./tailwind.css";

// const client = new ApolloClient({
//   // uri: "http://192.168.1.28:3000/graphql",
//   // uri: "http://0.0.0.0:3000/graphql",
//   uri: "https://backend.gigatechproducts.com/graphql",
//   cache: new InMemoryCache(),
// });

const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

function App() {
	return (
		<div>
			<QueryClientProvider client={client}>
				<ProductContextProvider>
					<Router />
				</ProductContextProvider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
