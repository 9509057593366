import { css, cx } from "@emotion/css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { NAVIGATION_LINKS_BORDER_COLOR, RED } from "../../colors";
import About from "../../images/menu-01.svg";
import { IMenuItem } from "../../types";

type Props = {
	data: IMenuItem;
};

const MenuItem = ({ data }: Props) => {
	const history = useHistory();
	const [showSubMenu, setShowSubMenu] = useState(false);

	const hasSubmenu = (data?.subMenus?.length as number) > 0;
	const S3_LINK = process.env.REACT_APP_AWS_IMAGES_CLOUDFRONT_URI;

	return (
		<li
			className={"relative cursor-pointer"}
			{...(hasSubmenu && {
				onMouseEnter: () => setShowSubMenu(true),
				onMouseLeave: () => setShowSubMenu(false),
			})}
			style={{ flex: 0.96 }}
		>
			<a
				{...(!!data?.link
					? {
							href: data?.link,
					  }
					: {})}
				className={cx(
					"flex items-center border-l border-r",
					css`
						border-left: 1px solid ${NAVIGATION_LINKS_BORDER_COLOR};
						border-right: 1px solid ${NAVIGATION_LINKS_BORDER_COLOR};
					`,
				)}
			>
				<span style={{ marginRight: 8.5 }}>
					<img
						src={data.iconName ? `${S3_LINK}/${data.iconName}` : About}
						alt=""
						style={{ height: 16, width: 16, marginBottom: 2 }}
					/>
				</span>
				<p className="text-base font-semibold not-italic">{data?.name}</p>
			</a>

			<div
				className={cx(
					"shadow  flex-col items-start min-h-28",
					css`
						/* display: "flex"; */
						display: ${showSubMenu ? "flex" : "none"};
						position: absolute;
						background-color: white;
						top: 58px;
						left: 0px;
						padding: 20px;
						width: 300px;
						transition: all 0.4s ease-in-out;
						z-index: 100;
						font-weight: 500;
						& > a {
							&:hover {
								color: ${RED};
							}
						}
					`,
				)}
				{...(hasSubmenu && {
					onMouseEnter: () => setShowSubMenu(true),
					onMouseLeave: () => setShowSubMenu(false),
				})}
			>
				{data?.subMenus?.map((item, i) =>
					Boolean(item?.link) ? (
						<a className="pb-3" key={i} href={item?.link}>
							{item.name}
						</a>
					) : (
						<p className="pb-3" key={i}>
							{item.name}
						</p>
					),
				)}
			</div>
		</li>
	);
};

export default MenuItem;
