import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from "./RouteConfig";
import Layout from "../components/Layout";

const AppRouter = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
      </Layout>
    </Router>
  );
};

function RouteWithSubRoutes(route: any) {
  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export default AppRouter;
