import React from "react";
import { FaBuilding, FaWhatsapp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { CONTACT_ADDRESS_COLOR, FOOTER_CONTACT_ICON_COLOR } from "../../colors";
import { IconContext, IconType } from "react-icons";
import { css } from "@emotion/css";
import Building from "../../images/building-solid.svg";
import Mail from "../../images/mail.svg";
import Phone from "../../images/phone.svg";
interface IContact {
	heading: string;
	email: string;
	phone: string;
	contact: string;
}

interface IContactItem {
	title: string;
	// Icon: IconType;
	image: string;
}

const ContactItem: React.FC<IContactItem> = ({ title, image }) => {
	return (
		<div className="text-white flex flex-col md:items-center md:flex-row">
			{/* <Icon style={{ color: FOOTER_CONTACT_ICON_COLOR }} /> */}
			<img
				src={image}
				alt=""
				style={{ width: 16, height: 16, marginBottom: 2 }}
			/>
			<h1
				className="md:mt-0 text-white"
				style={{ fontSize: 16, marginLeft: 5.5 }}
			>
				{title}
			</h1>
		</div>
	);
};

const Contact: React.FC<IContact> = ({ heading, email, phone, contact }) => {
	const splitContact = contact.split("|");
	return (
		<div
			className={css`
				margin-bottom: 50px;
			`}
		>
			<IconContext.Provider value={{ size: "16px", className: "font-bold" }}>
				<ContactItem image={Building} title={heading} />
				<div
					style={{
						color: CONTACT_ADDRESS_COLOR,
						marginTop: 3,
						marginBottom: 8.5,
					}}
					className="mb-2"
				>
					{splitContact.map((line, i) => (
						<p key={i} className="mb-0.5">
							{line}
						</p>
					))}
				</div>
				<ContactItem image={Phone} title={phone} />
				<ContactItem image={Mail} title={email} />
			</IconContext.Provider>
		</div>
	);
};

export default Contact;
