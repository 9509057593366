import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { useGetProductsByAttributeIds } from "../../hooks/useQueryHooks";
import loader from "../../images/loader.gif";
import { ATTRIBUTE_NAMES, IProduct } from "../../types";
import { getUniqueComp } from "../../utils/getFilteredProducts";
import OtherProduct from "./OtherProduct";
import { useProductContext } from "../../context/ProductContext";
import {
	convertToSearchParams,
	extractSearchParams,
} from "../../utils/extractSearchParams";
import { useSearchParams } from "../../hooks/useSearchParams";

type Props = {};

const Main = () => {
	const { handlePageTitle } = useProductContext();
	const location = useLocation();
	const searchParams = useSearchParams();
	const history = useHistory();
	const category = location.pathname.split("/")[1];
	const [attributes, setAttributes] = useState<any[]>([]);

	const queryIds = Object.values(extractSearchParams(location.search));

	const {
		data: response,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
	} = useGetProductsByAttributeIds(
		category,
		JSON.stringify([...queryIds].filter(Boolean)),
		undefined,
		{
			onSuccess: (data: { pages: any[] }) => {
				// console.log({ data });
				const newData = data?.pages?.flatMap((page) => {
					const processedBatch = getUniqueComp(
						page.products || [],
						ATTRIBUTE_NAMES["MANUF COMP"],
					);
					return processedBatch;
				});

				const uniqueValues = [
					...new Map(newData.map((item) => [item["_id"], item])).values(),
				];
				setAttributes(uniqueValues);
			},
		},
	);

	const onClick = (slug: string, id: string) => {
		try {
			const params = convertToSearchParams(
				Object.fromEntries(searchParams.entries()),
			);

			console.log({ params });

			handlePageTitle(
				slug?.replaceAll("_", " ").replaceAll("-", " ") + " Product Category",
			);
			history.push({
				pathname: `/${category}/${slug}-${id}`,
				search: params,
			});
		} catch (error) {
			console.log({ error });
		}
	};

	useEffect(() => {
		if (!response?.pages?.length || response?.pages?.length === 0) return;

		if (response?.pages.length === 1) fetchNextPage();
	}, [response?.pages.length]);

	return (
		<div className="relative min-h-screen">
			{/* {category ? (
        <h1 className="font-bold text-gray-700 text-xl xl:pl-10 lg:pl-5 pr-5 xsm:px-4 capitalize">
          {category?.split("-").join(" ")}
        </h1>
      ) : null} */}
			{(isLoading || isFetching) && (
				<div className="flex items-center justify-center">
					<img
						src={loader}
						alt="loader"
						className="w-16 absolute top-72 left-96"
					/>
				</div>
			)}
			{attributes?.length > 0 && (
				<InfiniteScroll
					dataLength={attributes.length || 0}
					next={fetchNextPage}
					hasMore={hasNextPage as boolean}
					endMessage={<></>}
					loader={
						<h4 className="xl:pl-10 lg:pl-5 pr-5 xsm:px-4 text-gray-700 py-2">
							Loading ...
						</h4>
					}
				>
					{attributes?.map((item) => {
						return (
							<div key={item.value}>
								<OtherProduct
									heading={item.value.toLowerCase()}
									onClickHandler={onClick}
									id={item._id}
									slug={item.slug}
								/>
							</div>
						);
					})}
				</InfiniteScroll>
			)}
		</div>
	);
};

export default Main;
