import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { useGetRelatedProducts } from "../hooks/useQueryHooks";
import loader from "../images/loader.gif";
import OtherProduct from "../pages/ProductPage/OtherProduct";
import { IProduct } from "../types";

type Props = {
	data: IProduct | undefined;
};

const RelatedProducts = ({ data }: Props) => {
	const [page, setPage] = useState(0);
	const history = useHistory();
	const location = useLocation();
	const category = location.pathname.split("/")[1];
	const attribute = location.pathname.split("/")[2];
	const variant = location.pathname.split("/")[3];
	const [total, setTotal] = useState(0);
	const [products, setProducts] = useState<IProduct[]>([]);
	const relatedProductsKey = data?.gt_sub_class as string;

	console.log({ relatedProductsKey });

	const {
		data: relatedProducts,
		isLoading,
		refetch,
		isFetching,
	} = useGetRelatedProducts(category, page, relatedProductsKey, {
		enabled: Boolean(relatedProductsKey),
		onSuccess: (data) => {
			// @ts-ignore
			setProducts((prev) => [...prev, ...data?.data]);
		},
	});
	const handleNextPage = () => {
		setPage((prev) => prev + 1);
	};

	const onClick = (productID: string) => {
		history.push(`/${category}/${attribute}/${variant}/${productID}`);
	};

	useEffect(() => {
		// @ts-ignore
		const totalCount = relatedProducts?.headers?.["X-Total-Count"];
		if (totalCount) {
			setTotal(Number(totalCount));
		}
	}, [relatedProducts]);

	useEffect(() => {
		if (page === 0) return;

		refetch();
	}, [page]);
	return (
		<div className="relative min-h-screen">
			{/* {category ? (
        <h1 className="font-bold text-gray-700 text-xl xl:pl-10 lg:pl-5 pr-5 xsm:px-4 capitalize">
          {category?.split("-").join(" ")}
        </h1>
      ) : null} */}
			{(isLoading || isFetching) && (
				<div className="flex items-center justify-center">
					<img
						src={loader}
						alt="loader"
						className="w-16 absolute top-72 left-96"
					/>
				</div>
			)}
			{products?.length > 0 && (
				<InfiniteScroll
					dataLength={products?.length || 0}
					next={handleNextPage}
					hasMore={products?.length < total}
					loader={
						<h4 className="xl:pl-10 lg:pl-5 pr-5 xsm:px-4 text-gray-700 py-2">
							Loading ...
						</h4>
					}
				>
					{products?.map((item) => {
						return (
							<div key={item._id}>
								<OtherProduct
									heading={item.name}
									description={item.sales_description_long}
									isItem={true}
									onClickHandler={onClick}
									id={item._id}
									slug={item._id}
								/>
							</div>
						);
					})}
				</InfiniteScroll>
			)}
		</div>
	);
};

export default RelatedProducts;
