import { ICategory, IDropdownData, IProduct } from "../types";
import axios from "../utils/axios";
import { useMutation, UseMutationOptions } from "react-query";

export const useRequestQuote = (options?: UseMutationOptions) => {
	return useMutation({
		mutationFn: async (payload) => {
			const { data: responseData } = await axios.post("/user/quote", payload);
			return responseData;
		},
		...options,
	});
};
