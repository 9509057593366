import { createContext, useContext, useEffect, useState } from "react";

interface IProductContext {
  pageTitle: string;
  handlePageTitle: (value: string) => void;
}

const ProductContext = createContext<IProductContext>({} as IProductContext);

export const ProductContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [pageTitle, setPageTitle] = useState("MANUFACTURER COMPATIBLE");
  const handlePageTitle = (value: string) => {
    setPageTitle(value);
  };
  return (
    <ProductContext.Provider value={{ pageTitle, handlePageTitle }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = (): IProductContext => {
  return useContext(ProductContext);
};
