export const extractSearchParams = (search: string) => {
	if (!search) return {};
	const params = search.split("?")[1];
	const allParams = Object.fromEntries(
		params.split("&").map((el) => el.split("=")),
	);

	return allParams;
};

export const convertToSearchParams = (params: Record<string, string>) => {
	if (!params) return "";

	const searchParams = Object.entries(params).reduce((acc, curr, index) => {
		const prefix = index === 0 ? acc : acc + "&";
		return (acc = `${prefix}${curr[0]}=${curr[1]}`);
	}, "?");

	return searchParams;
};
