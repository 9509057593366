import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useAttribute = () => {
	const history = useHistory();

	const onAttributeClick = useCallback(
		(
			attr: { attrName: string; valueId: string; valueSlug: string },
			catSlug: string,
		) => {
			const path = history.location.pathname.split("/")[1]
				? history.location.pathname
				: `/${catSlug}`;

			// if(history.location.search.includes())
			const { attrName, valueId, valueSlug } = attr;
			if (attrName.toLowerCase() === "manuf comp") {
				history.push({
					pathname: `/${catSlug}/${valueSlug}-${valueId}`,
					search: history.location.search,
				});
				return;
			}

			const allAttributes = history.location.search.split("?")[1];
			if (!allAttributes) {
				history.push({
					pathname: path,
					search: `?${attrName}=${valueId}`,
				});

				return;
			}
			let attributes = allAttributes.split("&");

			const existingIndex = attributes.findIndex((el) => el.includes(attrName));

			if (existingIndex > -1) {
				attributes[existingIndex] = `${attrName}=${valueId}`;
			} else {
				attributes = [...attributes, `${attrName}=${valueId}`];
			}

			history.push({
				pathname: path,
				search: `?${attributes.join("&")}`,
			});
		},
		[],
	);

	return { onAttributeClick };
};

export default useAttribute;
