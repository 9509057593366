import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { useGetProductsByAttributeIds } from "../../hooks/useQueryHooks";
import loader from "../../images/loader.gif";
import { ATTRIBUTE_NAMES, IProduct } from "../../types";
import { getProductNamesByAttribute } from "../../utils/getFilteredProducts";
import OtherProduct from "./OtherProduct";
import { extractSearchParams } from "../../utils/extractSearchParams";
import { useSearchParams } from "../../hooks/useSearchParams";

type Props = {};

const SearchPage = () => {
	const location = useLocation();
	const history = useHistory();
	const [products, setProducts] = useState<IProduct[]>([]);
	const searchParams = useSearchParams();

	const {
		data: response,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		refetch,
	} = useGetProductsByAttributeIds(
		undefined,
		undefined,
		searchParams.get("query") || undefined,
		{
			onSuccess: (data: { pages: any[] }) => {
				// console.log({ data });
				const newData = data?.pages?.flatMap((page) => {
					return page.products;
				});

				setProducts([...newData]);
			},
		},
	);

	const onClick = (product: IProduct) => {
		const { category, attributes } = product;

		const manufComp = attributes?.find((el: any) => el?.name === "MANUF COMP");
		const manufCat = attributes?.find((el: any) => el?.name === "MANUF CAT");

		const attribute = `${manufComp?.slug}-${manufComp?._id}`;
		const variant = `${manufCat?.slug}-${manufCat?._id}`;

		history.push(
			`/${category?.abbreviation}/${attribute}/${variant}/${product?._id}`,
		);
	};

	useEffect(() => {
		if (response?.pages?.length && response?.pages?.length === 0) return;

		refetch();
	}, [response?.pages.length]);

	return (
		<div className="relative min-h-screen">
			{/* {category ? (
        <h1 className="font-bold text-gray-700 text-xl xl:pl-10 lg:pl-5 pr-5 xsm:px-4 capitalize">
          {category?.split("-").join(" ")}
        </h1>
      ) : null} */}
			{(isLoading || isFetching) && (
				<div className="flex items-center justify-center">
					<img
						src={loader}
						alt="loader"
						className="w-16 absolute top-72 left-96"
					/>
				</div>
			)}
			{products?.length > 0 && (
				<InfiniteScroll
					dataLength={products.length || 0}
					next={fetchNextPage}
					hasMore={hasNextPage as boolean}
					endMessage={<></>}
					loader={
						<h4 className="xl:pl-10 lg:pl-5 pr-5 xsm:px-4 text-gray-700 py-2">
							Loading ...
						</h4>
					}
				>
					{products?.map((item) => {
						return (
							<div key={item._id}>
								<OtherProduct
									data={item}
									heading={item.name}
									description={item.sales_description_long}
									isItem={true}
									variant="secondary"
									onClickHandler={onClick}
									id={item._id}
									slug={item._id}
								/>
							</div>
						);
					})}
				</InfiniteScroll>
			)}
		</div>
	);
};

export default SearchPage;
