import { Modal } from "antd";
import React, { useState } from "react";
import logo from "../images/logo.svg";
import { useForm } from "react-hook-form";
import { useRequestQuote } from "../hooks/useMutationHooks";
import { FaSpinner } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

type Props = {
	children: React.ReactNode;
};

const QuoteModal = ({ children }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { mutate, isLoading } = useRequestQuote({
		onSuccess: () => {
			toast.success("Quote requested successfully");
			handleCancel();
		},
	});
	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	const onSubmit = (values: any) => {
		console.log({ values });
		mutate({
			...values,
		});
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		reset();
	};
	return (
		<>
			<span onClick={showModal}>{children}</span>
			<Modal
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				centered
				className="border-8 overflow-hidden quote-modal"
				maskClosable
				footer={false}
			>
				<div className="container">
					<div>
						<img src={logo} alt={"logo"} width={200} height={"100%"} />
					</div>
					<div className="contact-form px-5 py-8">
						<h4 className="text-2xl font-bold font-roboto mb-4">
							Contact us for a quote
						</h4>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex items-center gap-4 mb-1">
								<div className="flex-1">
									<input
										placeholder="Name*"
										{...register("name", {
											required: { value: true, message: "Name is required" },
										})}
									/>

									<p className="text-red-700 text-xs pl-2 pt-1 h-5">
										{errors["name"]?.message}
									</p>
								</div>
								<div className="flex-1">
									<input placeholder="Company" {...register("company")} />
									<p className="text-red-700 text-xs pl-2 pt-1 h-5">
										{errors["company"]?.message}
									</p>
								</div>
							</div>
							<div className="flex items-center gap-4 mb-3">
								<div className="flex-1">
									<input
										placeholder="Phone*"
										type="number"
										{...register("phone", {
											required: { value: true, message: "Phone is required" },
											valueAsNumber: true,
										})}
									/>
									<p className="text-red-700 text-xs pl-2 pt-1 h-5">
										{errors["phone"]?.message}
									</p>
								</div>
								<div className="flex-1">
									<input
										placeholder="Email*"
										{...register("email", {
											required: {
												value: true,
												message: "Email is required",
											},
										})}
									/>
									<p className="text-red-700 text-xs pl-2 pt-1 h-5">
										{errors["email"]?.message}
									</p>
								</div>
							</div>
							<div className="flex-1">
								<textarea
									placeholder="Message"
									rows={8}
									className="w-full"
									{...register("message")}
								></textarea>
								<p className="text-red-700 text-xs pl-2 pt-1 h-5">
									{errors["message"]?.message}
								</p>
							</div>
							<div className="w-1/2 mx-auto mt-4">
								<button
									className="bg-primary py-2 w-full text-white rounded-full flex items-center justify-center gap-3"
									disabled={isLoading}
								>
									{isLoading && <FaSpinner className="animate-spin" />}
									Submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
			<Toaster position="bottom-right" />
		</>
	);
};

export default QuoteModal;
