import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetProductsByAttributeIds } from "../../hooks/useQueryHooks";
import { ATTRIBUTE_NAMES, IProduct } from "../../types";
import { AxiosResponse } from "axios";
import OtherProduct from "./OtherProduct";
import loader from "../../images/loader.gif";
import {
	filterAttributes,
	getUniqueComp,
} from "../../utils/getFilteredProducts";
import { useProductContext } from "../../context/ProductContext";
import {
	convertToSearchParams,
	extractSearchParams,
} from "../../utils/extractSearchParams";
import { useSearchParams } from "../../hooks/useSearchParams";

type Props = {};

let shouldRefetch = false;

const Products = () => {
	const { handlePageTitle } = useProductContext();
	const location = useLocation();
	const searchParams = useSearchParams();
	const history = useHistory();
	const category = location.pathname.split("/")[1];
	const attribute = location.pathname.split("/")[2];
	const [attributes, setAttributes] = useState<any[]>([]);

	const queryIds = Object.values(extractSearchParams(location.search));
	const pathId = attribute.split("-")[1];

	console.log({
		queryIds: JSON.stringify([...queryIds, pathId].filter(Boolean)),
	});

	const {
		data: response,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetching,
		refetch,
	} = useGetProductsByAttributeIds(
		category,
		JSON.stringify([...queryIds, pathId].filter(Boolean)),
		undefined,
		{
			onSuccess: (data: { pages: any[] }) => {
				// console.log({ data });
				const newData = data?.pages?.flatMap((page) => {
					const processedBatch = getUniqueComp(
						page.products || [],
						ATTRIBUTE_NAMES["MANUF CAT"],
					);
					return processedBatch;
				});
				// setProducts((prev) => [...prev, ...(data?.data as IProduct[])]);
				setAttributes((prev) => [...newData]);
			},
		},
	);

	const onClick = (slug: string, id: string) => {
		const params = convertToSearchParams(
			Object.fromEntries(searchParams.entries()),
		);
		history.push({
			pathname: `/${category}/${attribute}/${slug}-${id}`,
			search: params,
		});
	};

	useEffect(() => {
		if (response?.pages.length === 0) return;

		refetch();
	}, [response?.pages.length]);

	useEffect(() => {
		if (!shouldRefetch) {
			shouldRefetch = true;
			return;
		}

		refetch();
	}, [attribute]);

	return (
		<div className="relative min-h-screen">
			{/* {category ? (
        <h1 className="font-bold text-gray-700 text-xl xl:pl-10 lg:pl-5 pr-5 xsm:px-4 capitalize">
          {category?.split("-").join(" ")}
        </h1>
      ) : null} */}
			{(isLoading || isFetching) && (
				<div className="flex items-center justify-center">
					<img
						src={loader}
						alt="loader"
						className="w-16 absolute top-72 left-96"
					/>
				</div>
			)}
			{attributes?.length > 0 && (
				<InfiniteScroll
					dataLength={attributes.length || 0}
					next={fetchNextPage}
					hasMore={hasNextPage as boolean}
					endMessage={<></>}
					loader={
						<h4 className="xl:pl-10 lg:pl-5 pr-5 xsm:px-4 text-gray-700 py-2">
							Loading ...
						</h4>
					}
				>
					{attributes?.map((item) => {
						return (
							<div key={item.value}>
								<OtherProduct
									heading={item.value.toLowerCase()}
									// description={item.description}
									// isItem={true}
									onClickHandler={onClick}
									id={item._id}
									slug={item.slug}
								/>
							</div>
						);
					})}
				</InfiniteScroll>
			)}
		</div>
	);
};

export default Products;
