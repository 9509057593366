import { ATTRIBUTE_NAMES, IProduct } from "../types";

export const getUniqueComp = (products: IProduct[], attr: string) => {
	// Create a Map to store unique attributes
	const uniqueAttributesMap = new Map();

	// Iterate through each product
	products.forEach((product) => {
		// Iterate through attributes of each product
		product.attributes.forEach((attribute: any) => {
			// Create a unique key for each attribute based on "name," "value," and "category"
			const uniqueKey = `${attribute.name}_${attribute.slug}_${attribute.category}`;

			// Check if the unique key is not already in the Map
			if (!uniqueAttributesMap.has(uniqueKey)) {
				// Add the attribute to the Map using the unique key
				uniqueAttributesMap.set(uniqueKey, {
					_id: attribute._id,
					name: attribute.name,
					value: attribute.value,
					slug: attribute.slug,
					category: attribute.category,
					description: product.sales_description_long,
				});
			}
		});
	});

	// Convert the Map values back to an array of unique attributes
	const uniqueAttributes = Array.from(uniqueAttributesMap.values()).filter(
		(el) => el.name === attr,
	);
	return uniqueAttributes;
};

export const getProductNamesByAttribute = (
	products: IProduct[],
	attrName: string,
	attrSlug: string,
) => {
	return products.filter((prod) =>
		prod.attributes.some(
			(attr: any) => attr.name === attrName && attr.slug === attrSlug,
		),
	);
};

export const filterAttributes = (products: IProduct[], attrSlug: string) => {
	console.log({ products });
	const filtered = products.filter((prod) =>
		prod.attributes.some((attr: any) => attr.slug === attrSlug),
	);

	return getUniqueComp(filtered, ATTRIBUTE_NAMES["MANUF CAT"]);
};
