import Index from "../pages/ProductPage";
import ProductList from "../pages/ProductPage/Main";
import Products from "../pages/ProductPage/Products";
import Variants from "../pages/ProductPage/Variants";
import SearchPage from "../pages/ProductPage/SearchPage";
import Detail from "../pages/ProductPage/ProductDetails";
import React from "react";

export interface RouteConfigProps {
  path: string;
  component: React.FC;
  exact?: boolean;
  routes?: unknown;
  protected?: boolean;
}

export const routes: RouteConfigProps[] = [
    {
    path: "/search",
    component: SearchPage,
    protected: false,
    exact: true,
  },
  {
    path: "/",
    component: Index,
    protected: false,
    exact: true,
  },
  {
    path: "/:category",
    component: ProductList,
    protected: false,
    exact: true,
  },
  {
    path: "/:category/:attribute",
    component: Products,
    protected: false,
    exact: true,
  },
  {
    path: "/:category/:attribute/:product",
    component: Variants,
    protected: false,
    exact: true,
  },
  {
    path: "/:category/:attribute/:product/:model",
    component: Detail,
    protected: false,
  },
];
