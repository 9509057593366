import { createContext } from "react";
import { ManufacturerContextType } from "../interfaces/index";

const ManufacturerContext = createContext<ManufacturerContextType>({
  items: [{ id: 0, item_name: "", sales_discription_long: "", product_Slug: "" }],
  loading: false,
  manufCat: [{ manuf_cat: "", manuf_cat_id: 0, manuf_cat_slug: "" }],
  manufCompMain: [{ manuf_comp: "", manuf_comp_id: 0 }],
  manufCompSide: [{ manuf_comp: "", manuf_comp_id: 0 }],
  // navigationCat: "",
  // navigationComp: "",
  onCompClick: () => {},
  onItemClick: () => {},
  product: {
    attribute_2: "",
    attribute_3: "",
    attribute_4: "",
    attribute_5: "",
    attribute_7: "",
    attribute_8: "",
    id: 0,
    item_name: "",
    attribute_1: {
      attribute_1: "",
      attribute_1_id: 0,
      import_ref_id: 0,
    },
    attribute_6: {
      attribute_6: "",
      attribute_6_id: 0,
      import_ref_id: 0,
    },

    manuf_comp_part: "",
    product_image: "",
    sales_discription_long: "",
    spec_sheet: "",
  },
  // gtClass: [{ gt_class: "", gt_class_id: 0, import_ref_id: 0, gt_slug: "" }],
  onSubdownTranscieverClickHandler: () => {},
  onSubdownFiberClickHandler: () => {},
  onSubdownCopperClickHandler: () => {},
  visible: false,
  background: "",
  onManufacturerClick: () => {},
  onFormFactorClick: () => {},
  onTransferRateClick: () => {},
  onInterfacePortClick: () => {},
  onManufacturerClickCables: () => {},
  // attribute9: [{ attribute_9: "", attribute_9_id: 0 }],
  // attribute2FiberCables: [""],
  // attribute4FiberCables: [""],
  localGtClassId: 0,
  onFormFactorClickFiberCable: () => {},
  onTransferRateClickFiberCable: () => {},
  onInterfacePortClickFiberCables: () => {},
  onManufacturerClickCopperCables: () => {},
  onFormFactorClickCopperCables: () => {},
  onTransferRateClickCopperCables: () => {},
  onInterfacePortClickCopperCables: () => {},
  onInputChangeHandler: () => {},
  onSearchIconClickedHandler: () => {},
  // attribute1Copper: [
  //   {
  //     attribute_1: "",
  //     attribute_1_id: 0,
  //   },
  // ],
  // isCopper: false,
  // isFiber: false,
  // isTransciever: false,
  onCatTransClick: () => {},
  onCatFiberClick: () => {},
  onCatCopperClick: () => {},
  onBreadcrumCat: () => {},
  onBreadcrumClass: () => {},
  onSearchEnterPressed: () => {},
  isSubdownComponent: false,
  onBreadcrumComp: () => {},
  // attribute1Id: 0,
  // attribute2AsArg: "",
  // manufCompId: 0,
  // gtSubSubClassId: 0,
  // attribute2NameFiber: "",
  // attribute4NameFiber: "",
  // attribute9Id: 0,
  // attribute1IdCopper: 0,
  // attribute4NameCopper: "",
  // attribute9IdCopper: 0,
  // manufCompIdCopper: 0,
  // manufCompIdFiber: 0,
  // attribute: [{ attribute_1: "", attribute_1_id: 0, import_ref_id: 0 }],
  // attribute2: [{ gt_sub_sub_class: "", gt_sub_sub_class_id: 0 }],
  // attribute3: [""],
  transcieverIds: {
    attribute1Id: 0,
    attribute2Id: "",
    gtSubSubClassId: 0,
    manufCompId: 0,
  },
  fiberIds: {
    manufCompId: 0,
    attribute9Id: 0,
    attribute2Id: "",
    attribute4Id: "",
  },
  copperIds: {
    manufCompId: 0,
    attribute1Id: 0,
    attribute4Id: "",
    attribute9Id: 0,
  },
  transcieverData: {
    attribute1: [{ attribute_1: "", attribute_1_id: 0, import_ref_id: 0 }],
    attribute2: [""],
    gtSubSubClass: [{ gt_sub_sub_class: "", gt_sub_sub_class_id: 0 }],
  },
  fiberData: {
    attribute2: [""],
    attribute4: [""],
    attribute9: [{ attribute_9: "", attribute_9_id: 0 }],
  },
  copperData: {
    attribute1: [
      {
        attribute_1: "",
        attribute_1_id: 0,
      },
    ],
    attribute4: [""],
    attribute9: [{ attribute_9: "", attribute_9_id: 0 }],
  },
  gtClass: {
    id: 0,
    value: [{ gt_class: "", gt_class_id: 0, import_ref_id: 0, gt_slug: "" }],
  },
  isClassFlags: { isTransciever: false, isFiber: false, isCopper: false },
  total: 0,
  paginationFunction: () => {},
  inputValue: "",
  onChangeInputSearchHandler: () => {},
  sidebarVisible: false,
});
const ManufacturerProvider = ManufacturerContext.Provider;

export { ManufacturerContext, ManufacturerProvider };
