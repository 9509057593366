import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "../../hooks/useSearchParams";
import { ICategory, IDropDownFilterAttribute } from "../../types";

interface DropdownProps {
	attribute: IDropDownFilterAttribute;
	category: ICategory;
	onClick: (
		attr: {
			attrName: string;
			valueId: string;
			valueSlug: string;
		},
		catSlug: string,
	) => void;
	expandedAttrPanel?: string | null;
	handleOpenExpandedAttrPanel: (name: string) => void;
	handleCloseExpandedAttrPanel: () => void;
}

const SubDropdown: React.FC<DropdownProps> = ({
	attribute,
	category,
	onClick,
	handleOpenExpandedAttrPanel,
	expandedAttrPanel,
	handleCloseExpandedAttrPanel,
}) => {
	const isVisible = attribute.name === expandedAttrPanel;
	const location = useLocation();
	const searchParams = useSearchParams();

	const handleOpen = () => {
		const attr = location.pathname.split("/")[2];
		const val = attribute.value.some((v) => v.slug === attr);
		if (val) {
			handleOpenExpandedAttrPanel(attribute?.name as string);
		}
	};

	const handleClick = () => {
		if (expandedAttrPanel === attribute.name) {
			handleCloseExpandedAttrPanel();
			return;
		}

		handleOpenExpandedAttrPanel(attribute?.name as string);
	};

	// useEffect(() => {
	//   handleOpen();
	// }, [location, attribute]);

	return (
		<div>
			<div
				className={`bg-lightBlue border-gray-600 border-2 cursor-pointer flex items-center justify-between rounded p-2  mx-2 text-white mb-1`}
				onClick={handleClick}
			>
				<p className="font-bold unselectableText text-sm capitalize">
					{attribute.name?.toLowerCase() ||
						attribute?.value?.[0]?.name?.toLowerCase()}
				</p>
				<span>
					<FontAwesomeIcon icon={faChevronDown} />
				</span>
			</div>
			<div
				className={
					isVisible
						? "block mx-2 p-2 bg-dark rounded-b text-white mb-2 unselectableText"
						: "hidden "
				}
			>
				{attribute?.value?.map((val, i) => {
					let isChecked;
					if (val?.name?.toLowerCase().includes("manuf comp")) {
						isChecked = location.pathname.split("/")?.[2]?.includes(val._id);
					} else {
						isChecked = searchParams
							.get(val?.name as string)
							?.includes(val._id);
					}
					const attr = {
						attrName: val?.name,
						valueId: val?._id,
						valueSlug: val?.slug,
					};
					return (
						<div
							key={val.slug + i}
							className="relative hover:text-blue-300 pl-1 sidebarDropDown text-sm mb-1"
							onClick={(e) => onClick(attr, category?.abbreviation)}
						>
							<label className="uppercase">
								{val?.altValue?.toLowerCase() || val?.value?.toLowerCase()}
							</label>
							<input
								type="radio"
								name={attribute.name}
								value={val.slug}
								key={val.slug}
								className=" absolute right-0"
								checked={isChecked}
								defaultChecked={isChecked}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default SubDropdown;
