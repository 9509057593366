import { css, cx } from "@emotion/css";
import React from "react";
import { RED } from "../colors";
import Logo from "../images/GT-logo-new.svg";
import { CgClose } from "react-icons/cg";

const Popup404: React.FC<{ setPopup: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setPopup,
}) => {
  return (
    <div className="w-full h-full fixed top-0 left-0 bg-opacity-40 flex z-50 min-h-screen">
      <div
        className="bg-black  w-full h-full absolute top-0 left-0 bg-opacity-40 z-10"
        onClick={() => setPopup(false)}
      />
      <div
        className={cx(
          "z-50 overflow-hidden rounded-xl",
          css`
            width: 500px;
            margin: auto;
            position: absolute;
            left: 71%;
            top: 50%;
            transform: translate(-50%, -50%);
            @media screen and (min-width: 320px) {
              left: 71%;
            }
            @media screen and (min-width: 375px) {
              left: 68%;
            }
            @media screen and (min-width: 768px) {
              left: 62%;
            }
            @media screen and (min-width: 1024px) {
              left: 56%;
            }
            .popup--height {
              @media screen and (min-width: 320px) {
                height: 450px;
                width: 320px;
              }
              @media screen and (min-width: 768px) {
                height: 440px;
              }
              @media screen and (min-width: 1024px) {
                width: 500px;
              }
            }
            @media screen and (max-width: 590px) {
              zoom: 80%;
            }
            @media screen and (max-width: 460px) {
              zoom: 70%;
              max-height: calc(100vh + 165px);
              overflow: auto;
            }
          `,
        )}
      >
        <div className="pt-20 px-11 flex flex-col items-center bg-white h-full popup--height relative rounded-xl bg-gray-100 text-center shadow-xl">
          <div
            className="absolute right-4 top-4 text-xl cursor-pointer"
            onClick={() => setPopup(false)}
          >
            <CgClose />
          </div>
          <div>
            <img src={Logo} alt="Logo" />
          </div>
          <div className="text-xl text-gray-700 text-semibold mb-4 mt-8">
            <p>This file seems to be unavailable for download at the moment</p>
            <p className="mt-6">Please contact GigaTech Products for requested documents</p>
          </div>
          <a
            href="https://www.gigatechproducts.com/contact/"
            className="uppercase text-gray-200 text-sm text-bold px-3 py-1"
            style={{ backgroundColor: RED, fontWeight: 700 }}
            onClick={() => setPopup(false)}
          >
            contact us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Popup404;
