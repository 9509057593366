import React, { useContext, useState, useEffect } from "react";
import Dropdown from "../../components/Dropdown";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SidebarSubDropdown from "./SidebarSubDropdown";
import { ManufacturerContext } from "../../context/ManufacturerContext";
import { ICategory, IDropDownFilter, IDropdownData } from "../../types";
import { useLocation } from "react-router-dom";

interface SidebarDropdownProps {
	data: IDropDownFilter;
	border?: string;
	onCategoryClick: (catSlug: string) => void;
	onAttrClick: (
		attr: {
			attrName: string;
			valueId: string;
			valueSlug: string;
		},
		catSlug: string,
	) => void;
	expandedPanel: string | null;
	handleOpenExpandedPanel: (abbr: string) => void;
	handleCloseExpandedPanel: () => void;
}

const SidebarDropdown: React.FC<SidebarDropdownProps> = ({
	data,
	border,
	onCategoryClick,
	onAttrClick,
	expandedPanel,
	handleOpenExpandedPanel,
	handleCloseExpandedPanel,
}) => {
	const [expandedAttrPanel, setExpandedAttrPanel] = useState<string | null>(
		null,
	);

	const handleOpenExpandedAttrPanel = (name: string) => {
		setExpandedAttrPanel(name);
	};
	const handleCloseExpandedAttrPanel = () => {
		setExpandedAttrPanel(null);
	};

	const location = useLocation();
	const isDropdownOpen = data.category?.abbreviation === expandedPanel;

	const handleClick = () => {
		if (expandedPanel === data.category?.abbreviation) {
			handleCloseExpandedPanel();
			return;
		}
		onCategoryClick(data.category?.abbreviation);
	};

	useEffect(() => {
		const category =
			location.pathname.split("/")[1] === data.category?.abbreviation;
		if (category) {
			handleOpenExpandedPanel(data.category?.abbreviation);
		}
	}, [location, data]);

	useEffect(() => {
		if (expandedPanel !== data.category?.abbreviation) {
			handleCloseExpandedAttrPanel();
		}
	}, [expandedPanel]);

	return (
		<div
			className={`${border || ""} border-b-2 border-gray-600 bg-darker pt-3`}
		>
			<div onClick={handleClick}>
				<Dropdown
					title={data.category?.abbreviation}
					label={data.label || data.category?.name}
					icon={faChevronRight}
					expanded={isDropdownOpen}
				/>
			</div>

			{isDropdownOpen && (
				<div className="mt-2 py-3 px-2 bg-dark mx-2">
					{data?.attributes?.map((attr, i) => (
						<SidebarSubDropdown
							key={i}
							attribute={attr}
							category={data.category}
							onClick={onAttrClick}
							handleOpenExpandedAttrPanel={handleOpenExpandedAttrPanel}
							handleCloseExpandedAttrPanel={handleCloseExpandedAttrPanel}
							expandedAttrPanel={expandedAttrPanel}
						/>
						// <div>{attr.name}</div>
					))}
					{/* {title === "Fiber Cables" ? (
            <>
              <SidebarSubDropdown
                title="Manufacturer"
                manufComp={manufCompSide}
                onClickHandler={onManufacturerClickCables}
                testCase={5}
                visible={true}
              />
              <SidebarSubDropdown
                title="Cable Type"
                attribute9={fiberData.attribute9}
                onClickHandler={onFormFactorClickFiberCable}
                testCase={6}
              />
              <SidebarSubDropdown
                title="Interface Port / Connector"
                attribute2FiberCables={fiberData.attribute2}
                onClickHandler={onTransferRateClickFiberCable}
                testCase={7}
              />
              <SidebarSubDropdown
                title="Max Distance / Cable Length"
                attribute4FiberCables={fiberData.attribute4}
                onClickHandler={onInterfacePortClickFiberCables}
                testCase={8}
              />
            </>
          ) : title === "Copper CAT Cables" ? (
            <>
              <SidebarSubDropdown
                title="Manufacturer"
                manufComp={manufCompSide}
                onClickHandler={onManufacturerClickCopperCables}
                testCase={1}
                visible={true}
              />
              <SidebarSubDropdown
                title="Form Factor"
                attribute1Copper={copperData.attribute1}
                onClickHandler={onFormFactorClickCopperCables}
                testCase={11}
              />
              <SidebarSubDropdown
                title="Boot Type"
                attribute9={copperData.attribute9}
                onClickHandler={onTransferRateClickCopperCables}
                testCase={10}
              />
              <SidebarSubDropdown
                title="Max Distance"
                attribute4FiberCables={copperData.attribute4}
                onClickHandler={onInterfacePortClickCopperCables}
                testCase={8}
              />
            </>
          ) : (
            <>
              <SidebarSubDropdown
                title="Manufacturer"
                manufComp={manufCompSide}
                onClickHandler={onManufacturerClick}
                testCase={1}
                visible={true}
                CompName={title}
              />
              <SidebarSubDropdown
                title="Form Factor"
                attribute={transcieverData.attribute1}
                onClickHandler={onFormFactorClick}
                testCase={2}
              />
              <SidebarSubDropdown
                title="Transfer Rate"
                attribute2={transcieverData.gtSubSubClass}
                onClickHandler={onTransferRateClick}
                testCase={3}
              />
              <SidebarSubDropdown
                title="Interface Ports"
                attribute3={transcieverData.attribute2}
                onClickHandler={onInterfacePortClick}
                testCase={4}
              />
            </>
          )} */}
				</div>
			)}
		</div>
	);
};
export default SidebarDropdown;
